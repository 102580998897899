.footer {
    background-color: var(--white);
    padding: 6rem 0;

    @include --xs {
        padding: 5rem 0;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 2.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        @include --sm {
            flex-direction: column;

            &:last-child {
                .footer__block {
                    &:last-child {
                        justify-content: flex-start;
                        order: -1;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }

    &__block {
        display: flex;
        width: auto;
        flex-grow: 1;

        &:last-child {
            justify-content: flex-end;
        }

        @include --sm {
            width: 100%;
        }
    }
}

.bottom-nav {
    flex-grow: 1;

    &__list {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include --md {
            flex-wrap: wrap;
        }

        @include --sm {
            justify-content: flex-start;
            padding-top: 3rem;
        }
    }

    &__item {
        margin-right: 5rem;

        &:last-child {
            margin-right: 0;
        }

        @include --xl {
            margin-right: 4rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include --lg {
            margin-right: 3.5rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include --md {
            margin-right: 0;
            margin-left: 4rem;
            margin-bottom: 1rem;
        }

        @include --sm {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 3rem;
            width: calc(100% / 3);
            padding-right: 1rem;
        }

        @include --xs {
            width: 50%;
        }
    }

    &__link {
        font-family: $helvetica;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: normal;
        color: var(--bg-color);

        &:hover {
            text-decoration: underline;
        }

        &.active {
            color: var(--orange);
        }

        @include --lg {
            font-size: 1.4rem;
        }
    }
}

.copyright {
    font-family: $helvetica;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: normal;
    color: var(--bg-color);
}
