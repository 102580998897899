.cases {
  background-color: var(--white);
  padding: 10rem 0;

  @include --lg { padding: 9rem 0; }

  @include --md { padding: 8rem 0; }

  @include --sm { padding: 7rem 0; }

  @include --xs { padding: 6rem 0; }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -3rem;

    @include --md {
      margin-right: 0;
      justify-content: center;
    }
  }

  &__item {
    width: 50%;
    padding-right: 3rem;
    padding-bottom: 3rem;

    @include --md {
      width: 100%;
      padding-right: 0;
      padding-bottom: 4rem;
      max-width: 80rem;
    }
  }

  &__more {
    margin-top: 5rem;
    font-family: $helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: em(24/20);
    text-align: center;
    color: var(--black);

    @include --md { margin-top: 4rem; }

    @include --sm { margin-top: 3rem; }
  }
}

.single-case {
  &__section {
    position: relative;
    &_light {
      background-color: var(--white);

      & .title {
        color: var(--deep);

        & + p {
          margin-top: 4.7rem;

          @include --xs { margin-top: 3rem; }
        }
      }
    }

    &_dark {
      background-color: var(--bg-color);

      &_white {
        display: flex;
        position: relative;
      }
    }

    &_yellow { background-color: #fab53e}

    &_gray { background-color: #f0f0f0; }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8rem 0;
    flex-wrap: wrap;

    @include --md { padding: 5rem 0; }

    @include --xs { padding: 4rem 0; }
  }

  &__column {
    width: 50%;
    padding-left: 3rem;
    z-index: 2;

    @include --md {
      padding-left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 5rem;

      &:last-child { margin-bottom: 0; }
    }

    @include --xs {
      margin-bottom: 4rem;
      & > h3 { width: 100%; }
    }

    &-img { text-align: center; }
  }

  &__img {
    max-width: 100%;
    height: auto;
    width: 49rem;
    position: relative;
    z-index: 1;
    vertical-align: middle;

    @include --md { width: 40rem; }
  }

  &__bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    & > img {
      max-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
    }

    &-solar {
      &-1 {
        top: 25%;
        left: 19%;
        @include --md {
          top: 10%;
          left: 20%;
        }
        @include --sm {
          top: 2%;
          left: 16%;
        }
        @include --xs { top: 1%; }
      }

      &-2 {
        top: 72%;
        left: 31%;
        @include --md {
          top: 23%;
          left: 70%;
        }
        @include --sm {
          top: 19%;
          left: 79%;
        }
        @include --xs {
          top: 23%;
          left: 79%;
        }
      }
    }

    &-kore {
      &-1 {
        left: -1%;
        top: 5%;
        @include --xs {
          left: -3%;
          top: 1%;
        }
      }

      &-2 {
        left: 11%;
        top: 19%;
        @include --md {
          left: 32%;
          top: 7%;
        }
        @include --sm { top: 3%; }
        @include --xs { left: 68%; }
      }

      &-3 {
        left: 56%;
        top: 85%;
        @include --md {
          left: 85%;
          top: 55%;
        }
        @include --sm {
          left: 76%;
          top: 45%;
        }
        @include --xs {
          left: 88%;
          top: 35%;
        }
      }
    }
  }
}

.custom-shape-divider-bottom-1618829551 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.preview {
  display: flex;
  padding: 10rem 0;
  position: relative;
  min-height: 100vh;
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &__right {
    animation-name: backInRight;
    animation-duration: 2s;
    position: absolute;
    left: 0;
    bottom: 0;
    width: auto;
  }

  &__up {
    animation-name: backInUp;
    animation-duration: 1s;
  }

  & .container {
    position: relative;
    display: flex;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;

    @include --xl { width: auto; }

    &-bottom { height: auto; }
  }

  &__after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
    animation-name: fadeIn;
    animation-duration: 2s;
    transition-delay: 1s;
    transition: all 0s;
    overflow: hidden;

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

  }

  &__row {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;

    @include --md {
      flex-direction: column;
      justify-content: center;
    }

    &-center {
      justify-content: space-around;
      text-align: center;
      flex-direction: column;
    }
  }

  &__column {
    width: 50%;
    animation-name: fadeInUpSlow;
    animation-duration: 1.2s;

    .preview__title-h1 { color: var(--white); }

    & > p { color: var(--white); }

    &:first-child {
      width: calc(100% / 12 * 4);

      &.preview__column-width {
        width: calc(100% / 12 * 5);
        position: relative;
        z-index: 2;
        margin-bottom: 2.5rem;
      }
    }

    &:last-child { margin-right: -3rem; }

    @include --md {

      &:first-child {
        width: 80%;
        margin-bottom: 5.5rem;
        &.preview__column-width { width: 65%; }
      }

      &:last-child {
        width: 80%;
        margin-right: 0;
      }
    }

    @include --sm {

      &:first-child {
        width: 100%;

        &.preview__column-width { width: 100%; }
      }

      &:last-child { width: 100%; }
    }

    @include --xs {
      &:first-child { margin-bottom: 2rem; }
    }
  }

  &__video {
    position: relative;
    display: flex;
    justify-content: center;
    width: auto;

    &:last-child { margin-right: 0; }

    &_img {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }

    &_block {
      max-width: 100%;
      width: 86%;
      height: auto;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-53%);
      display: flex;
      flex-direction: column;


      @include --xs { width: 86.5%; }
      @media screen and (max-height: 34rem) { width: 86.5%; }
      &-delivery { width: 76%; }
    }

    &_top {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }

    &-v {
      max-width: 100%;
      height: auto;
    }
  }

  &__title-h1 {
    font-family: $proxima;
    font-style: normal;
    font-weight: 900;
    font-size: 5rem;
    line-height: em(61/50);
    letter-spacing: em(5/50);
    text-transform: uppercase;
    color: var(--light);

    & + .title { margin-top: 2.9rem; }

    @include --xs { font-size: 4rem; }

    @include --xs { font-size: 3rem; }
  }

  & .title + p { margin-top: 2.9rem; }

  &__block {
    position: relative;

    &-small {
      @include --xs { width: 25%; }
    }
  }
}

.case-text {
  color: var(--text-color);
  position: relative;
  z-index: 1;

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @include --md {
      align-items: center;
      flex-direction: column;
    }
  }

  &__column {
    padding: 10rem 0;
    width: 50%;
    margin: auto 0;

    &:first-child { width: calc(100% / 12 * 5); }

    &_padding_top { padding-top: 0; }

    & > p {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 3rem;

      @include --xs { line-height: 2.5rem; }
    }

    &_width {
      @include --lg {
        &:first-child { width: calc(100% / 12 * 7); }
      }
    }

    @include --md { padding: 5rem 0; }

    @include --xs { padding: 3.5rem 0; }

    &_with-bg {

      &::after {
        content: "";
        position: absolute;
        top: -4.5rem;
        left: 50%;
        transform: translateX(50%);
        bottom: 0;
        height: 100%;
        width: 2px;
        background: linear-gradient( 180deg, rgba(196, 196, 196, 0) 16.4%, #CACACA 55.7%, rgba(196, 196, 196, 0) 97.69%);

        @include --md {
          top: 0;
          left: 42.5%;
          transform: translateX(-50%);
          height: 2px;
          width: 100%;
          background: linear-gradient(45deg, rgba(196, 196, 196, 0) 16.4%, #CACACA 55.7%, rgba(196, 196, 196, 0) 97.69%);
        }
      }
    }

    &_centered { margin: auto; }

    @include --md {
      width: 100%;

      &:first-child { width: 100%; }
      &:last-child { position: relative; }
    }
  }

  &__list {
    font-family: $proxima;
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -5rem;

    @include --sm { flex-direction: column; }

    &-title { text-transform: uppercase; }
  }

  &__item {
    width: 50%;
    padding: 0 3rem 5rem;

    @include --sm {
      padding: 0 0 3rem 0;
      width: 100%;

      &:last-child { padding-bottom: 5rem; }
    }
  }

  &__list-title {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: em(19/16);
    letter-spacing: em(2/16);
    color: var(--deep);
    margin-bottom: 0.7rem;
  }

  &__list-desc {
    font-weight: 800;
    font-size: 2.2rem;
    line-height: em(27/22);
    color: var(--bg-color);
  }
}

.stack {
  font-family: $helvetica;
  font-style: normal;
  color: var(--light);

  &__list {
    margin-bottom: -1.6rem;
    padding: 3.6rem 0 4.4rem;

    @include --xs { padding: 3rem 0; }
  }

  &__item {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: em(30/18);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: baseline;
    color: var(--white);

    &:before {
      content: "";
      min-width: 0.9rem;
      min-height: 0.9rem;
      border-radius: 50%;
      background-color: var(--gray);
      display: inline-block;
      margin-right: 1.6rem;
    }
  }

  &__text {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: em(24/14);
  }
}

.phone-line {
  &__content {
    & > div { overflow-y: hidden; }
  }

  &__item {
    display: inline-block;
    margin: 0 8rem;

    & > img {
      max-height: 34.8rem;
      display: block;
      height: auto;

      @include --sm { max-height: 30rem; }

      @include --xs { max-height: 27rem; }
    }

    & > span { display: block; }

    &-hidden {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }

    @include --md { margin: 0 4.5rem; }
    @include --xs { margin: 0 2rem; }


  }
}

.flow {
  &__wrap {
    padding: 10rem 0;
    text-align: center;

    @include --xs { padding: 5rem 0; }
  }

  &__row {
    padding: 7rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &_centered { justify-content: center; }

    @include --lg {
      padding: 5rem 0;
      flex-direction: column;
    }

    @include --sm { padding: 3rem 0; }
  }

  &__column {
    order: 2;
    width: 50%;
    text-align: center;

    @include --lg { width: 100%; }

    &_order {
      padding: 0 10%;

      &_first { order: 1; }

      @include --lg {
        padding: 0;
        order: 0;
        margin-bottom: 3rem;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-img {
      @include --sm {
        padding-right: 2rem;
        &:last-child { padding-right: 0; }
      }

      & > img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &__img {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 5.7rem;
    width: 5.7rem;
    border-radius: 50%;
    background-color: #d9b465;
    margin-bottom: 2rem;

    .icon-search:before {
      @include --xs { font-size: 2rem; }
    }

    @include --lg { margin: 0 auto 2rem auto; }

    @include --sm { margin-bottom: 1rem; }

    @include --xs {
      height: 4.5rem;
      width: 4.5rem;
    }

    &-icon {
      font-size: 3.3rem;
      height: auto;
      color: var(--white);

      @include --lg { font-size: 3rem; }

      @include --sm { font-size: 2.5rem; }
    }
  }

  &__title {
    color: var(--white);
    font-size: 4rem;
    font-weight: 900;
    line-height: 4.9rem;

    text-align: left;

    @include --xxl { font-size: 3.4rem; }

    @include --lg {
      font-size: 3rem;
      text-align: center;
    }

    @include --md { font-size: 2.5rem; }

    @include --xs { font-size: 3rem; }
  }
}

.use-techno {
  padding: 2rem 0;
  z-index: 2;
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include --md { margin-bottom: -6rem; }

    @include --sm { margin-bottom: -5rem; }
  }

  &__item {
    text-align: center;
    width: 16%;
    @include --md {
      width: 33.333%;
      margin-bottom: 6rem;
    }
    @include --xs {
      width: 50%;
      margin-bottom: 5rem;
    }
  }
}

.preview-case {
  overflow: hidden;
  position: relative;

  &__wrap {
    padding: 10rem 0;

    @include --lg { padding: 5rem 0; }
  }

  &__row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &_white {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      margin: 0 auto;
    }

    &_centered { justify-content: center; }

    @include --lg {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__column {

    &:first-child { z-index: 5; }
    & > img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
      @media screen and (orientation: landscape) {
        @media screen and (max-height: 42rem){ height: 30rem; }
      }
    }

    &_width {
      width: 45%;

      @include --lg { width: 80%; }
    }

    @include --lg {
      margin-bottom: 4rem;
      &_no-margin { margin: 0; }

      &:last-child { margin-bottom: 0; }
    }
  }

  &__block {
    text-align: center;
    margin-bottom: 5rem;

    @include --lg { margin-bottom: 0; }

    img {
      max-width: 100%;
      height: auto;
      width: 51rem;
    }

    &_left {
      text-align: left;

      @include --lg { text-align: center; }
    }

    &_right {
      text-align: right;

      @include --lg { text-align: center; }
    }
  }

  &__image {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;


    &-none {
      @include --xl { display: none; }
    }

    &-solar {
      @include --xl {
        background-image: url("/assets/images/content/img-solarpay-2.jpg");
        height: 75vh;
      }

      @include --md { height: 50vh; }
      @include --sm { height: 40vh; }
      @include --xs { height: 30vh; }

      @media screen and (max-height: 34rem) { height: 85vh; }
    }

    &-rock {
      @include --xl {
        background-image: url("/assets/images/content/rock-erp-preview.jpg");
        height: 75vh;
      }

      @include --md { height: 55vh; }
      @include --sm { height: 40vh; }
      @include --xs { height: 30vh; }

      @media screen and (max-height: 34rem) { height: 85vh; }
    }

    &-kore {
      @include --xl {
        background-image: url("/assets/images/content/kore-page-task.webp");
        height: 60vh;
      }

      @include --md { height: 50vh; }
      @include --sm { height: 40vh; }
      @include --xs { height: 30vh; }

      @media screen and (max-height: 34rem) { height: 85vh; }
    }

    &-delivery {
      @include --xl {
        background-image: url("/assets/images/content/delivery-ware-phone.jpg");
        height: 50vh;
      }

      @include --xs { height: 30vh; }

      @media screen and (max-height: 34rem) { height: 70vh; }
    }
  }

  &__inner {
    position: relative;

    & > img {
      background-size: cover;
      background-repeat: no-repeat;
      max-width: 100%;

      @include  --md {
        width: 76.8rem;
        height: 48.6rem;
      }

      @include --sm {
        width: 46.6rem;
        height: 29.5rem;
      }

      @include --xs {
        width: 28rem;
        height: 17.8rem;
      }
    }
  }

  &__dark-white {
    height: 46.7rem;
    position: absolute;
    width: 74.7rem;
    top: 2.3rem;
    background-size: cover;

    @include --md {
      width: 66.6rem;
      height: 41.6rem;
      top: 2.1rem;
    }

    @include --sm {
      width: 40.5rem;
      height: 25.3rem;
      top: 1.3rem;
    }

    @include --xs {
      width: 24.5rem;
      height: 15.3rem;
      top: 0.8rem;
    }
  }

  &__dark,
  &__white {
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    max-width: 100%;
    width: 50%;
    transition: all 0.1s;
  }

  &__white {
    left: 0;
    background-position: left top;
  }

  &__dark {
    right: 0;
    background-position: right top;
  }

  &__line {
    &_image {
      width: 4rem;
      height: 100%;
      top: 0;
      margin: 0 auto;
      z-index: 2;
      bottom: 0;
      left: 50%;
      position: absolute;
      transition: all 0.1s;
      max-width: 73.9rem;
    }

    &_cursor {
      margin-left: -4rem;
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      transition: all 0.1s;

      & > img { width: 100%; }
    }
  }

  &__left,
  &__right {
    width: 50%;
    max-width: 100%;
    height: 75rem;
    transition: all 0.1s;

    @include --md { height: 58.8rem; }

    @include --sm { height: 40rem; }

    @include --xs { height: 25rem; }
  }

  &__left { background-color: var(--bg-color); }

  &__right { background-color: var(--white); }
}

.case-other {
  padding: 5rem 0;

  @include --xs { padding: 0 0 4rem 0; }

  &__title { padding: 4rem 0; }

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.parallax {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -12rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  &__inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 12rem;
  }

  &__images {
    position: absolute;

    & > img {
      height: auto;
      width: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  &__solar {
    &-1 {
      left: 6%;
      top: 83%;

      @include --md {
        left: 2%;
        top: 87%;
      }

      @include --xs {
        left: 3%;
        top: 74%;
      }
    }

    &-2 {
      left: 45%;
      top: 13%;

      @include --md {
        left: 41%;
        top: 8%;
      }

      @include --xs {
        left: 32%;
        top: 3%;
      }
    }

    &-3 {
      left: 60%;
      top: 71%;

      @include --md {
        left: 70%;
        top: 65%;
      }

      @include --xs {
        left: 62%;
        top: 53%;
      }
    }

    &-4 {
      left: 90%;
      top: 12%;

      @include --md {
        left: 91%;
        top: 5%;
      }

      @include --xs {
        left: 80%;
        top: 2%;
      }
    }

    &-bg {
      &-1 {
        left: 14%;
        top: 20%;

        @include --xl {
          left: 21%;
          top: 18%;
        }

        @include --md {
          left: 16%;
          top: 14%;
        }

        @include --sm {
          left: 11%;
          top: 17%;
        }
      }

      &-2 {
        left: -3%;
        top: 38%;

        @include --lg {
          left: -4%;
          top: 41%;
        }

        @include --md {
          left: -6%;
          top: 30%;
        }

        @include --sm {
          left: -11%;
          top: 33%;
        }

        @include --xs {
          left: -17%;
          top: 42%;
        }
      }

      &-3 {
        left: 21%;
        top: 68%;

        @include --xl {
          left: 13%;
          top: 75%;
        }

        @include --lg {
          left: 7%;
          top: 79%;
        }

        @include --md {
          left: 1%;
          top: 70%;
        }

        @include --sm {
          left: 0;
          top: 82%;
        }

        @include --xs {
          left: -8%;
          top: 80%;
          display: none;
        }
      }

      &-4 {
        left: 83%;
        top: 71%;

        @include --xl {
          left: 80%;
          top: 66%;
        }

        @include --lg {
          left: 77%;
          top: 68%;
        }

        @include --md {
          left: 86%;
          top: 70%;
        }

        @include --sm {
          left: 93%;
          top: 64%;
        }
      }

      &-5 {
        left: 85%;
        top: 14%;

        @include --xl {
          left: 82%;
          top: 12%;
        }
      }

      &-6 {
        left: 93%;
        top: 68%;

        @include --xl {
          left: 86%;
          top: 48%;
        }

        @include --lg {
          left: 90%;
          top: 50%;
        }

        @include --md {
          left: 85%;
          top: 42%;
        }

        @include --sm {
          left: 82%;
          top: 32%;
        }

        @include --xs {
          left: 68%;
          top: 44%;
        }
      }
    }
  }

  &__kore-bg {
    &-1 {
      left: -1%;
      top: 17%;

      @include --sm { top: 11%; }
      @include --xs { top: 9%; }
    }
    &-2 {
      left: 10%;
      top: 69%;

      @include --md {
        left: 2%;
        top: 73%;
      }

      @include --sm {
        left: 0;
        top: 62%;
      }

      @include --xs { top: 49%; }
    }
    &-3 {
      left: 15%;
      top: 27%;

      @include --md { left: 11%; }
      @include --sm { top: 19%; }
      @include --xs {
        top: 13%;
        left: 21%;
      }
    }
    &-4 {
      left: 76%;
      top: 55%;

      @include --md {
        left: 90%;
        top: 49%;
      }
      @include --xs {
        left: 83%;
        top: 45%;
      }
    }
    &-5 {
      left: 92%;
      top: 15%;

      @include --xs {
        left: 72%;
        top: 7%;
      }
    }
  }

  &__delivery {
    &-1 {
      left: 10%;
      top: 15%;
      @include --lg {
        left: 4%;
        top: 9%;
      }
    }

    &-2 {
      left: 35%;
      top: 71%;

      @include --lg {
        left: 20%;
        top: 77%;
      }

      @include --sm {
        left: 10%;
        top: 85%;
      }
    }

    &-3 {
      left: 76%;
      top: 54%;
      @include --sm {
        left: 80%;
        top: 63%;
      }
      @include --xs { left: 70%; }
    }

    &-4 {
      left: 94%;
      top: 5%;

      @include --sm {
        left: 85%;
        top: 3%;
      }
    }

    &-bg {
      &-1 {
        left: 5%;
        top: 71%;

        @include --xl {
          left: 1%;
          top: 66%;
        }

        @include --lg { display: none; }
      }

      &-2 {
        left: 19%;
        top: 37%;

        @include --xl {
          left: 13%;
          top: 28%;
        }
        @include --lg {
          left: 7%;
          top: 31%;
        }
        @include --sm {
          left: 1%;
          top: 42%;
        }
        @include --xs { top: 45%; }
      }

      &-3 {
        left: 27%;
        top: 11%;

        @include --xl {
          left: 23%;
          top: 7%;
        }
        @include --xs {
          left: 17%;
          top: 10%;
        }
      }

      &-4 {
        left: 80%;
        top: 70%;

        @include --xl {
          left: 73%;
          top: 70%;
        }

        @include --lg { display: none;}
      }

      &-5 {
        left: 75%;
        top: 48%;

        @include --xl {
          left: 78%;
          top: 44%;
        }

        @include --lg {
          left: 85%;
          top: 47%;
        }
        @include --sm {
          left: 84%;
          top: 53%;
        }
        @include --xs {
          left: 58%;
          top: 81%;
        }
      }

      &-6 {
        left: 68%;
        top: 19%;

        @include --xl { left: 71%; }
        @include --lg {
          left: 78%;
          top: 23%;
        }
        @include --xs {
          left: 83%;
          top: 21%;
        }
      }
    }
  }

  &__img {
    position: absolute;
    top: 12rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &-rock {
      background-image: url("/assets/images/content/single-case-rockerp-preview.webp");
      &.parallax__img { top: 0; }
    }
    &-barber {
      background-image: url("/assets/images/content/barber-pattern.webp");
      animation-name: fadeIn;
      animation-duration: 1.2s;

      &.parallax__img:after { display: none; }

      @media screen and (orientation: landscape) {
        @media screen and (max-height: 42rem){ background-size: auto; }
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      line-height: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
}

#detach-button-host { display: none; }