html,
body {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: $proxima;
    color: var(--light);
    background-color: var(--bg-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
    line-height: 1.5;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.visuallyhidden {
    position: absolute;
    width: 1px !important;
    height: 1px !important;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}
