.canvas {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 3rem;
    width: 100%;
    height: 100%;
}

.logo {
    & > a {
        display: flex;
        align-items: center;
    }
}

.description {
    font-family: $helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: em(24/14);
    color: var(--light);

    &:before {
        content: '';
        display: inline-block;
        height: 2rem;
        width: 2rem;
        border-top: 0.2rem solid var(--orange);
        border-left: 0.2rem solid var(--orange);
        transform: translateX(-3rem);
        transition: 0.2s;

        @include --lg {
            height: 1.8rem;
            width: 1.8rem;
            transform: translate(-2rem, 1rem);
        }

        @include --xs {
            transform: translate(-1rem, 0);
            margin-bottom: -0.5rem;
        }
    }

    &:hover {
        &:before {
            transform: translate(-2rem, 1.5rem);

            @include --lg {
                transform: translate(-2rem, 1rem);
            }

            @include --xs {
                transform: translate(-1rem, 0);
                margin-bottom: -0.5rem;
            }
        }
    }
}

p {
    font-size: 1.4rem;
    line-height: em(24/14);
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.gray_text {
    color: var(--deep);
}

.mouse-scroll {
    background-color: var(--bg-color);
    width: 2.2rem;
    height: 3.2rem;
    box-shadow: inset 0 0 0 0.2rem #979797;
    border-radius: 2.6rem;
    position: absolute;
    left: 50%;
    bottom: 2rem;
    margin-left: -1.1rem;
    will-change: transform, opacity;
    opacity: 0;
    visibility: hidden;
    animation-fill-mode: forwards;
    animation-duration: 800ms;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0.8em) translateZ(0);
    animation-delay: 0.5s;
    animation-name: fadeInDown;

    &.is-hidden {
        animation-delay: 0s;
        animation-duration: 1.6s;
        animation-name: fadeOutUp;
    }

    @include --xs {
        bottom: 1rem;
    }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 0.8rem;
        height: 0.8rem;
        background-color: var(--orange);
        margin-left: -0.4rem;
        top: 0.5rem;
        border-radius: 50%;
        animation-duration: 2.2s;
        animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
        animation-iteration-count: infinite;
        animation-name: scroll;
    }
}

.clutch {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__logo {
        margin-right: 1rem;
        & i {
            color: var(--bg-color);
            font-size: 3.5rem;
            line-height: 1;
        }
    }

    &__text {
        font-family: $helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: normal;
        text-transform: uppercase;
        color: var(--bg-color);
    }

    &:hover {
        & .clutch__text {
            text-decoration: underline;
        }
    }
}

.rating {
    display: inline-block;
    position: relative;
    margin-bottom: 0.3rem;

    &__star {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        overflow: hidden;
        margin-right: 0.2rem;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }

    & i {
        line-height: 1;
        font-size: 1.5rem;
        margin-right: 0.2rem;
        color: var(--orange);
        position: absolute;
        overflow: hidden;
    }

    & .icon-star {
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    & .icon-star-filled {
        z-index: 2;
        z-index: 199;
        width: 0;
    }

    &_1 {
        & .rating__star:nth-child(1) .icon-star-filled {
            width: 100%;
        }
    }

    &_2 {
        & .rating__star:nth-child(1),
        & .rating__star:nth-child(2) {
            & .icon-star-filled {
                width: 100%;
            }
        }
    }

    &_3 {
        & .rating__star:nth-child(1),
        & .rating__star:nth-child(2),
        & .rating__star:nth-child(3) {
            & .icon-star-filled {
                width: 100%;
            }
        }
    }

    &_4 {
        & .rating__star:nth-child(1),
        & .rating__star:nth-child(2),
        & .rating__star:nth-child(3),
        & .rating__star:nth-child(4) {
            & .icon-star-filled {
                width: 100%;
            }
        }
    }

    &_5 {
        & .rating__star:nth-child(1),
        & .rating__star:nth-child(2),
        & .rating__star:nth-child(3),
        & .rating__star:nth-child(4),
        & .rating__star:nth-child(5) {
            & .icon-star-filled {
                width: 100%;
            }
        }
    }
}

.error-page {
    padding: 15rem 0;

    @include --sm {
        padding: 12rem 0;
    }
}
