.button {
    font-family: $proxima;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    border-width: 0.2rem;
    border-style: solid;
    display: inline-block;
    transition: 0.3s;

    @include --xs {
        width: 100%;
        text-align: center;
    }

    &_default {
        color: var(--white);
        background-color: var(--orange);
        border-color: var(--orange);

        &:hover {
            background-color: var(--orange-hover);
            border-color: var(--orange-hover);
        }
    }

    &_bordered {
        color: var(--white);
        border-color: var(--orange);
        background-color: transparent;

        &:hover {
            background-color: var(--orange);
            border-color: var(--orange);
        }
    }

    &.size {
        &_small {
            font-size: 1.4rem;
            letter-spacing: em(2/14);
            font-weight: bold;
            border-radius: em(30/14);
            padding: em(4.5/14) em(23/14);
        }

        &_normal {
            font-size: 1.6rem;
            letter-spacing: em(2/16);
            font-weight: 900;
            border-radius: em(30/16);
            padding: em(12.6/16) em(35/16);
        }
    }

    &:disabled {
        background-color: var(--disabled);
        border-color: var(--disabled);
        color: var(--gray);
        pointer-events: none;
    }
}
