@mixin --xxxl {
    // max-width: 1909px
    @media screen and (max-width: $--xxxl) {
        @content;
    }
}

@mixin --xxl {
    // max-width: 1679px
    @media screen and (max-width: $--xxl) {
        @content;
    }
}

@mixin --xl {
    // max-width: 1439px
    @media screen and (max-width: $--xl) {
        @content;
    }
}

@mixin --lg {
    // max-width: 1279px
    @media screen and (max-width: $--lg) {
        @content;
    }
}

@mixin --md {
    // max-width: 1023px
    @media screen and (max-width: $--md) {
        @content;
    }
}

@mixin --sm {
    // max-width: 767px
    @media screen and (max-width: $--sm) {
        @content;
    }
}

@mixin --xs {
    // max-width: 479px
    @media screen and (max-width: $--xs) {
        @content;
    }
}

// px to rem
@function rem($px) {
    @return $px / 14px+0rem;
}

// px to em
@function em($em) {
    @return $em / 1 + 0em;
}

@function vh($quantity) {
    @return calc(var(--vh, 1vh) * #{$quantity});
}
