.page-head {
    padding: 17rem 0 10rem;
    display: flex;
    align-items: center;

    @include --lg {
        padding: 17rem 0 9rem;
    }

    @include --md {
        flex-direction: column;
        align-items: flex-start;
        padding: 13.1rem 0 8rem;
    }

    @include --sm {
        padding: 12.1rem 0 6rem;
    }

    @include --xs {
        padding: 12.1rem 0 5rem;
    }

    &__column {
        &:first-child {
            width: calc(100% / 12 * 7);

            @include --md {
                width: 100%;
            }
        }

        &:last-child {
            width: calc(100% / 12 * 5);

            @include --md {
                width: calc(100% / 12 * 7);
            }

            @include --sm {
                width: 100%;
            }
        }
    }
}

.text-content {
    background-color: var(--white);
    padding: 10rem 0;

    @include --lg {
        padding: 9rem 0;
    }

    @include --md {
        padding: 8rem 0;
    }

    @include --sm {
        padding: 6rem 0;
    }

    @include --xs {
        padding: 5rem 0;
    }

    &__row {
        display: flex;
        align-items: flex-start;
        margin-bottom: 9rem;

        @include --md {
            margin-bottom: 8rem;
            flex-direction: column;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include --sm {
            margin-bottom: 6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include --xs {
            margin-bottom: 5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__column {
        &:first-child {
            width: calc(100% / 12 * 5);
            padding-right: 6rem;

            &:empty {
                padding: 0;
            }

            @include --md {
                width: 100%;
                padding-right: 0;
                padding-bottom: 3rem;

                &:empty {
                    padding: 0;
                }
            }
        }

        &:last-child {
            width: calc(100% / 12 * 7);

            @include --md {
                width: 100%;
            }
        }

        & .title {
            color: var(--bg-color);
        }

        & p {
            font-family: $helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: em(24/14);
            color: var(--text-color);
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
