.technologies {
    background-color: var(--white);
    padding: 10rem 0;

    @include --lg {
        padding: 9rem 0;
    }

    @include --md {
        padding: 8rem 0;
    }

    @include --sm {
        padding: 6rem 0;
    }

    @include --xs {
        padding: 5rem 0;
    }

    &__row {
        border-bottom: 0.1rem solid rgba($color: var(--black-rgb), $alpha: 0.1);
        padding-bottom: 10rem;
        margin-bottom: 10rem;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        @include --lg {
            padding-bottom: 9rem;
            margin-bottom: 9rem;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @include --md {
            padding-bottom: 8rem;
            margin-bottom: 8rem;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @include --sm {
            padding-bottom: 6rem;
            margin-bottom: 6rem;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        @include --xs {
            padding-bottom: 5rem;
            margin-bottom: 5rem;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .description {
            color: var(--deep);
            width: calc(100% / 6 * 5);

            @include --sm {
                width: 100%;
            }
        }

        @include --md {
            flex-direction: column;
        }
    }

    &__head-col {
        width: 50%;

        @include --md {
            width: 100%;
        }
    }

    &__title {
        font-family: $proxima;
        font-style: normal;
        font-weight: 900;
        font-size: 5rem;
        line-height: em(61/50);
        color: var(--deep);

        @include --sm {
            font-size: 4rem;
        }

        @include --xs {
            font-size: 3.5rem;
            margin-bottom: 1rem;
        }
    }

    &__body {
        padding-top: 10rem;

        @include --lg {
            padding-top: 9rem;
        }

        @include --md {
            padding-top: 8rem;
        }

        @include --sm {
            padding-top: 6rem;
        }

        @include --xs {
            padding-top: 5rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        @include --lg {
            margin-bottom: -6rem;
        }

        @include --sm {
            margin-bottom: -5rem;
        }
    }

    &__item {
        width: calc(100% / 8);
        text-align: center;

        @include --lg {
            width: 25%;
            margin-bottom: 6rem;
        }

        @include --sm {
            width: 50%;
            margin-bottom: 5rem;
        }
    }

    &__icon {
        width: 6rem;
        height: 6rem;
        margin: auto;
        display: flex;

        & > img {
            max-width: 100%;
            height: auto;
            margin: auto;
        }

        @include --sm {
            width: 5rem;
            height: 5rem;
        }
    }

    &__subtitle {
        font-family: $helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 1.6rem;
        line-height: em(18/16);
        letter-spacing: em(1/16);
        text-transform: uppercase;
        color: var(--black);
        margin-top: 3.5rem;

        @include --xs {
            font-size: 1.4rem;
        }
    }
}
