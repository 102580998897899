// Colors
html {
    --bg-color: #121516;
    --bg-color-rgb: 18, 21, 22;
    --white: #ffffff;
    --white-rgb: 255, 255, 255;
    --alter-white: #fefefe;
    --black: #000000;
    --black-rgb: 0, 0, 0;
    --light: #dedede;
    --alter-light: #b4b4b4;
    --gray: #959595;
    --orange: #fa5207;
    --orange-rgb: 250, 82, 7;
    --orange-hover: #812800;
    --alter-orange-rgb: 113, 62, 40;
    --deep: #161616;
    --disabled: #e1e1e1;
    --text-color: #414141;
}

[data-theme="dark"] {
    --bg-color: #ffffff; // done
    --bg-color-rgb: 255, 255, 255; // done
    --white: #161616; // done
    --white-rgb: 255, 255, 255;
    --alter-white: #fefefe;
    --black: #000000;
    --black-rgb: 0, 0, 0;
    --light: #dedede;
    --alter-light: #b4b4b4;
    --gray: #959595;
    --orange: #fa5207;
    --orange-rgb: 250, 82, 7;
    --orange-hover: #812800;
    --alter-orange-rgb: 113, 62, 40;
    --deep: #161616;
    --disabled: #e1e1e1;
    --text-color: #4e4e4e;
}

// Responsive
$--xxxl: 1909px; //max-width: 1909px;
$--xxl: 1679px; //max-width: 1679px;
$--xl: 1439px; //max-width: 1439px;
$--lg: 1279px; //max-width: 1279px;
$--md: 1023px; //max-width: 1023px;
$--sm: 767px; //max-width: 767px;
$--xs: 479px; //max-width: 479px;

// Fonts
$proxima: "Proxima Nova", sans-serif;
$helvetica: "HelveticaNeueCyr", sans-serif;
