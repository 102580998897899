@font-face {
    font-family: "fontello";
    src: url("../../Fonts/fontello/fontello.eot?4179135");
    src: url("../../Fonts/fontello/fontello.eot?4179135#iefix") format("embedded-opentype"),
        url("../../Fonts/fontello/fontello.woff2?4179135") format("woff2"),
        url("../../Fonts/fontello/fontello.woff?4179135") format("woff"),
        url("../../Fonts/fontello/fontello.ttf?4179135") format("truetype"),
        url("../../Fonts/fontello/fontello.svg?4179135#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-arrow_left:before {
    content: "\e800";
} /* '' */
.icon-arrow_right:before {
    content: "\e801";
} /* '' */
.icon-angle_left:before {
    content: "\e802";
} /* '' */
.icon-angle_right:before {
    content: "\e803";
} /* '' */
.icon-mail:before {
    content: "\e804";
} /* '' */
.icon-phone:before {
    content: "\e805";
} /* '' */
.icon-placeholder:before {
    content: "\e806";
} /* '' */
.icon-arrow-left:before {
    content: "\e807";
} /* '' */
.icon-check:before {
    content: "\e80b";
} /* '' */
.icon-arrow-right:before {
    content: "\e80c";
} /* '' */
.icon-star:before {
    content: "\e810";
} /* '' */
.icon-star-filled:before {
    content: "\e811";
} /* '' */
.icon-clutch:before {
    content: "\e812";
} /* '' */
.icon-login:before {
    content: "\e816";
} /* '' */
.icon-search:before {
    content: "\e817";
    font-size: 2.6rem;
} /* '' */
.icon-profile:before {
    content: "\e818";
} /* '' */
.icon-success:before {
    content: "\e850";
} /* '' */
.icon-unsuccess:before {
    content: "\e851";
} /* '' */
