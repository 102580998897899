.title {
    font-family: $proxima;
    font-style: normal;
    font-weight: 900;
    color: var(--alter-white);

    &_dark {
        color: var(--bg-color);
    }

    &_h1 {
        line-height: em(122/100);
        letter-spacing: em(10/100);
        margin-bottom: em(56/100);
        text-transform: uppercase;
        font-size: 15rem;

        @include --xxl {
            font-size: 10rem;
        }

        @include --lg {
            font-size: 8rem;
        }

        @include --md {
            font-size: 7rem;
        }

        @include --sm {
            font-size: 4.8rem;
        }

        @include --xs {
            font-size: 11vw;
        }
    }

    &_h2 {
        text-transform: uppercase;
        font-size: 8rem;
        line-height: em(97/80);
        letter-spacing: em(10/80);

        @include --xxl {
            font-size: 7rem;
        }

        @include --lg {
            font-size: 6rem;
        }

        @include --md {
            font-size: 5rem;
        }

        @include --sm {
            font-size: 4.4rem;
        }

        @include --xs {
            font-size: 9vw;
        }
    }

    &_h3 {
        font-size: 3.8rem;
        line-height: em(46/38);

        @include --xxl {
            font-size: 3.4rem;
        }

        @include --xl {
            font-size: 3.2rem;
        }

        @include --sm {
            font-size: 3rem;
        }
    }

    &_h4 {
        font-size: 3rem;
        line-height: em(40/30);
        letter-spacing: em(2/30);
        text-transform: uppercase;

        @include --xs {
            font-size: 2.6rem;
        }
    }

    &_h5 {
        font-size: 2rem;
        line-height: em(24/20);
        letter-spacing: em(3/20);
        text-transform: uppercase;
    }

    &_stroke {
        color: transparent;
        -webkit-text-stroke-color: var(--gray);
        -webkit-text-stroke-width: 0.3rem;

        @include --sm {
            -webkit-text-stroke-width: 0.2rem;
        }

        @include --xs {
            -webkit-text-stroke-width: 0.1rem;
        }
    }

    &__line {
        display: block;
    }
}

.title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
