.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: .3s;

    &_sticky {
        box-shadow: 0rem 0.1rem 1rem rgba(var(--black-rgb), 0.6);
        background-color: var(--bg-color);
        transition: box-shadow 0s;
    }

    &__wrap {
        display: flex;
        align-items: center;
        padding: 4.5rem 0;

        @include --md {
            padding: 3rem 0;
            justify-content: space-between;
        }

        @media screen and (max-height: 375px) {
            padding: 1.5rem 0;
        }

        @include --xs {
            padding: 1.5rem 0;
        }

        & > .button {
            @include --md {
                display: none;
            }
        }
    }
}

.top-nav {
    flex-grow: 1;

    @include --md {
        display: none;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 5.3rem;

        @include --lg {
            padding-right: 5rem;
        }
    }

    &__item {
        margin-right: 7rem;

        &:last-child {
            margin-right: 0;
        }

        @include --xl {
            margin-right: 6rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include --xl {
            margin-right: 4rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__link {
        font-family: $helvetica;
        font-weight: bold;
        font-size: 1.6rem;
        line-height: normal;
        color: var(--white);
        transition: 0.25s;
        position: relative;

        &.active {
            color: var(--orange);
        }

        &:after {
            background-color: var(--orange);
            display: block;
            content: "";
            height: 2px;
            width: 0;
            left: 0;
            position:absolute;
            transition: width .3s ease-in-out;
        }
        &:hover:after {
            width: 100%;
        }

        @include --lg {
            font-size: 1.4rem;
        }
    }
}

.hamburger {
    display: none;
    width: 3.1rem;
    height: 3.1rem;
    position: relative;
    cursor: pointer;
    transition: 0.25s ease-in-out;

    @include --md {
        display: inline-block;
    }

    & * {
        transition: 0.25s ease-in-out;
    }

    span {
        display: block;
        background: var(--light);
        border-radius: 0.2rem;
    }

    .hamburger__lines {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        span {
            width: 3rem;
            height: 0.3rem;
            position: relative;
            top: 0;
            left: 0;
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(1) {
                transition-delay: 0.5s;
                width: 2.7rem;
            }

            &:nth-child(2) {
                transition-delay: 0.625s;
                width: 2.2rem;
            }

            &:nth-child(3) {
                transition-delay: 0.75s;
            }
        }
    }
    .hamburger__cross {
        position: absolute;
        height: 3.1rem;
        width: 3.1rem;
        left: 0;
        top: 0;
        transform: rotate(45deg);

        span {
            &:nth-child(1) {
                height: 0%;
                width: 0.3rem;
                position: absolute;
                top: 10%;
                left: 1.4rem;
                transition-delay: 0s;
            }
            &:nth-child(2) {
                width: 0%;
                height: 0.3rem;
                position: absolute;
                left: 10%;
                top: 1.4rem;
                transition-delay: 0.25s;
            }
        }
    }
}

.hamburger.active {
    .hamburger__lines {
        span {
            width: 0%;
            &:nth-child(1) {
                transition-delay: 0s;
            }
            &:nth-child(2) {
                transition-delay: 0.125s;
            }
            &:nth-child(3) {
                transition-delay: 0.25s;
            }
        }
    }

    .hamburger__cross {
        span {
            &:nth-child(1) {
                height: 80%;
                transition-delay: 0.625s;
            }
        }
        span {
            &:nth-child(2) {
                width: 80%;
                transition-delay: 0.375s;
            }
        }
    }
}

.mobile-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--bg-color);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    display: flex;
    overflow: hidden;

    &.active {
        transition: opacity 0.4s;
        opacity: 1;
        visibility: visible;

        & .mobile-nav__item {
            animation-name: fadeInDown;
        }

        & .button {
            animation-name: fadeInDown;
        }
    }

    &__middle {
        display: flex;
        margin: auto;
        flex-grow: 1;
        overflow: auto;
        margin: 9rem 2rem 2rem;
    }
}

.mobile-nav {
    margin: auto;

    &__item {
        text-align: center;
        margin-bottom: 7.5rem;
        will-change: transform, opacity;
        opacity: 0;
        visibility: hidden;
        animation-fill-mode: forwards;
        animation-duration: 800ms;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(2rem) translateZ(0);
        animation-delay: 0.2s;

        &:nth-child(2) {
            animation-delay: 0.4s;
        }

        &:nth-child(3) {
            animation-delay: 0.6s;
        }

        @include --xs {
            margin-bottom: 5.5rem;
        }
    }

    &__link {
        font-family: $helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.3rem;
        color: var(--light);

        &.active {
            color: var(--orange);
        }
    }

    & .button {
        will-change: transform, opacity;
        opacity: 0;
        visibility: hidden;
        animation-fill-mode: forwards;
        animation-duration: 800ms;
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(2rem) translateZ(0);
        animation-delay: 0.8s;
    }
}
