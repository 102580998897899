$grid-column: 12;

@mixin media-breackpoint-down {
    @content;
}

@for $i from 1 through $grid-column {
    .col-#{$i} {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: (100% / ($grid-column / $i));
        max-width: (100% / ($grid-column / $i));
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &-md {
        @include --md {
            flex-direction: column;
        }
    }
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;

    &-md {
        @include --md {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &-sm {
        @include --sm {
            flex-basis: 100%;
            max-width: 100%;
        }
    }
}

.root {
    min-height: 100vh;
    width: 100%;
}

.wrapper {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.content {
    flex-grow: 1;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 3rem;
    max-width: 177rem;

    @include --xs {
        padding: 0 2rem;
    }
}

.section:not(.intro) {
    padding: 11.5rem 0 13rem;

    @include --xl {
        padding: 10rem 0 11.5rem;
    }

    @include --lg {
        padding: 9rem 0 10.5rem;
    }

    @include --md {
        padding: 8rem 0 9.5rem;
    }

    @include --sm {
        padding: 6rem 0 7.5rem;
    }

    @include --xs {
        padding: 5rem 0 5rem;
    }
}
