.intro {
    min-height: 100vh;
    display: flex;
    position: relative;
    padding: 10rem 0 8rem;
    overflow: hidden;

    @include --md {
        min-height: 100vh;
    }

    & .container {
        display: flex;
    }

    &__wrap {
        margin: auto 0;
        width: 100%;
        position: relative;
    }

    & .description {
        position: absolute;
        right: 0;
        width: 27%;
        bottom: 23%;

        @include --xxl {
            bottom: 0;
            width: 33%;
            right: 7%;
        }

        @include --xl {
            width: 36%;
            right: 0;
        }

        @include --lg {
            bottom: -5rem;
        }

        @include --md {
            position: relative;
            bottom: auto;
            width: 100%;
            max-width: 46rem;
            margin-bottom: 4rem;
        }

        @include --sm {
            width: 100%;
        }
    }
}

.services {
    &__wrap {
        margin-top: 9.6rem;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 50%;
            bottom: 3.5rem;
            top: 3.5rem;
            background-color: rgba($color: var(--orange-rgb), $alpha: 0.04);

            @include --lg {
                bottom: 5.5rem;
                top: 5.5rem;
            }

            @include --md {
                display: none;
            }
        }

        @include --xxl {
            margin-top: 6rem;
        }

        @include --sm {
            margin-top: 4rem;
        }
    }

    &-tabs {
        display: flex;
        align-items: stretch;
        position: relative;

        &__controls {
            width: 25%;
            display: flex;

            &-list {
                display: flex;
                flex-direction: column;
                margin: auto;
            }

            &-item {
                margin-bottom: 10rem;

                &:last-child {
                    margin-bottom: 0;
                }

                @include --xl {
                    margin-bottom: 8rem;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &-btn {
                font-family: $proxima;
                font-style: normal;
                font-weight: bold;
                font-size: 2rem;
                line-height: em(22/18);
                color: var(--alter-light);
                transition: 0.25s;
                position: relative;

                & > span {
                    position: relative;
                }

                &:before {
                    content: "";
                    height: 0;
                    width: calc(100% + 1rem);
                    background-color: var(--orange);
                    position: absolute;
                    left: 50%;
                    bottom: 0.2rem;
                    transform: translateX(-50%);
                }

                &:hover {
                    color: var(--white);
                }

                &.active {
                    color: var(--white);

                    &:before {
                        transition-duration: 0.3s;
                        transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
                        height: 1rem;
                    }
                }

                @include --lg {
                    font-size: 1.8rem;
                }
            }

            @include --md {
                display: none;
            }
        }

        &__content {
            width: 75%;
            min-height: 49.5rem;
            position: relative;
            box-shadow: 0rem 0.8rem 3rem rgba(var(--black-rgb), 0.6);

            @include --xxl {
                min-height: 47.5rem;
            }

            @include --lg {
                min-height: 45.5rem;
            }

            @include --md {
                width: 100%;
                min-height: auto;
                box-shadow: none;

                &:before {
                    display: none;
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-image: linear-gradient(
                    90.11deg,
                    rgba($color: var(--black-rgb), $alpha: 0.8) 0.09%,
                    rgba($color: var(--black-rgb), $alpha: 0.8) 83.6%,
                    var(--black) 99.9%
                );
            }

            &-list {
                height: 100%;
            }

            &-item {
                height: 100%;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                overflow: hidden;
                display: flex;

                &.active {
                    & .services-tabs__content-overlay {
                        opacity: 1;
                        visibility: visible;
                    }

                    & .services-tabs__content-details {
                        opacity: 1;
                        visibility: visible;

                        & > p > span {
                            animation-name: slideInDown;
                            animation-duration: 1.2s;
                            transition-delay: 0.2s;
                            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
                        }
                    }

                    & .services-tabs__content-title {
                        & > span {
                            animation-name: slideInDown;
                            animation-duration: 1.2s;
                            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
                        }
                    }
                }

                @include --md {
                    position: relative;
                    box-shadow: 0rem 0.8rem 3rem rgba(var(--black-rgb), 0.6);

                    & .services-tabs__content-overlay {
                        opacity: 1;
                        visibility: visible;
                    }

                    & .services-tabs__content-details {
                        opacity: 1;
                        visibility: visible;

                        & > p > span {
                            animation-name: slideInDown;
                            animation-duration: 1.2s;
                            transition-delay: 0.2s;
                            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
                        }
                    }

                    & .services-tabs__content-title {
                        & > span {
                            animation-name: slideInDown;
                            animation-duration: 1.2s;
                            animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
                        }
                    }
                }
            }

            &-overlay {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                transition: 1.2s;
                opacity: 0;
                visibility: hidden;

                @include --md {
                    opacity: 1;
                    visibility: visible;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                        background-color: rgba($color: var(--black-rgb), $alpha: 0.8);
                    }
                }
            }

            &-details {
                background-color: rgba($color: var(--bg-color-rgb), $alpha: 0.9);
                color: var(--light);
                position: relative;
                display: inline-block;
                padding: 4.4rem 6.1rem 4.4rem 7.2rem;
                margin: auto auto auto 6.5rem;
                min-height: 34.5rem;
                max-width: 63.5rem;
                z-index: 2;
                opacity: 0;
                visibility: hidden;

                & .title + p {
                    margin-top: 2.5rem;

                    @include --xs {
                        margin-top: 2rem;
                    }
                }

                & p {
                    font-size: 1.4rem;
                    line-height: em(24/14);
                    color: var(--light);
                    overflow: hidden;

                    & > span {
                        display: inline-block;
                        position: relative;
                    }
                }

                @include --lg {
                    padding: 4rem 6rem;
                    min-height: 30rem;
                    margin: auto;
                }

                @include --md {
                    margin: 4rem auto;
                }

                @include --sm {
                    margin: 3rem;
                    padding: 4rem;
                }

                @include --xs {
                    margin: 2rem;
                    padding: 2rem;
                }
            }

            &-title {
                overflow: hidden;

                & > span {
                    display: inline-block;
                    position: relative;
                }
            }
        }
    }
}

.plan {
    position: relative;
    overflow: hidden;

    &__wrap {
        padding-top: 9.5rem;
        margin-right: -3rem;

        @include --xxl {
            padding-top: 6rem;
        }

        @include --sm {
            padding-top: 4rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: -3rem;

        @include --sm {
            margin-bottom: 0;
        }
    }

    &__item {
        width: 25%;
        min-height: 38.5rem;
        display: flex;
        padding-right: 3rem;
        margin-bottom: 3rem;
        transform: perspective(800px);
        transform-style: preserve-3d;

        @include --lg {
            width: 50%;
        }

        @include --sm {
            width: 100%;
            min-height: auto;
            height: 100%;
        }

        &:hover {
            & .plan-step-count {
                opacity: 0;
                transition-delay: 0s;
            }

            & .plan-step-overlay {
                opacity: 0.2;
            }

            & .plan-step__title {
                color: var(--orange);
                -webkit-text-stroke-color: transparent;
            }

            & .plan-step__desc {
                opacity: 1;
                margin-top: 3.5rem;

                @include --xxl {
                    margin-top: 1.7rem;
                }
            }
        }
    }

    &-step {
        transition: 0.5s;
        background-color: var(--deep);
        padding: 4rem;
        position: relative;
        box-shadow: 0rem 0.8rem 3rem rgba($color: var(--black-rgb), $alpha: 0.6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        flex-grow: 1;

        @include --xxl {
            padding: 4rem 2rem;
        }

        @include --xl {
            padding: 2rem;
        }

        @include --lg {
            padding: 4rem;
        }

        @include --md {
            padding: 3rem;
        }

        @include --xs {
            align-items: flex-start;
            padding: 2rem;
        }

        &-count {
            font-family: $proxima;
            font-size: 1.8rem;
            line-height: em(22/18);
            letter-spacing: em(3/18);
            font-weight: 900;
            color: var(--orange);
            position: absolute;
            left: 1rem;
            top: 1rem;
            transition: 0.15s;
            transition-delay: 0.5s;

            @include --lg {
                opacity: 0;
            }
        }

        &-overlay {
            position: absolute;
            left: -2rem;
            top: -2rem;
            width: 100%;
            height: 100%;
            padding: 2rem;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            opacity: 0;
            transition: 0.4s;
            pointer-events: none;
            box-sizing: content-box;

            @include --lg {
                opacity: 0.2;
            }
        }

        &__title {
            transition: 0.4s;
            position: relative;
            text-align: center;
            display: inline-block;
            color: var(--white);

            &.title {
                color: var(--white);

                @include --lg {
                    color: var(--orange);
                    -webkit-text-stroke-color: transparent;
                }
            }
        }

        &__desc {
            font-family: $helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: 1.4rem;
            line-height: em(24/14);
            color: var(--light);
            height: 0;
            opacity: 0;
            overflow: hidden;
            transition: 0.4s;
            text-align: center;

            @include --lg {
                opacity: 1;
                margin-top: 1.7rem;
                height: auto !important;
            }

            @include --xs {
                text-align: left;
            }
        }
    }
}

.cases {
    background-color: var(--white);
    overflow: hidden;

    & .title-wrap {
        margin-bottom: 5.5rem;

        @include --sm {
            margin-bottom: 4rem;
        }
    }

    &__bottom {
        padding-top: 4rem;
    }
}

.case-slide {
    display: block;
    width: 100%;
    min-width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-position: 0 0;
        background-size: 100% 210%;
        transition: 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
        background-image: linear-gradient(
            180deg,
            rgba($color: var(--bg-color-rgb), $alpha: 0),
            rgba($color: var(--bg-color-rgb), $alpha: 0.8),
            var(--bg-color)
        );

        @include --lg {
            background-position: center center;
            background-size: cover;
            transition: 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
            background-image: linear-gradient(
                358.01deg,
                var(--bg-color) 7.45%,
                rgba($color: var(--bg-color-rgb), $alpha: 0) 30.24%,
                rgba($color: var(--bg-color-rgb), $alpha: 0) 63.63%,
                var(--bg-color) 90.95%
            );
        }
    }

    &:hover {
        & .case-slide__content {
            bottom: 50%;
            transform: translateY(50%);

            & .description {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }

            @include --lg {
                bottom: auto;
                transform: translateY(0);

                & .description {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        & .case-slide__button {
            margin-top: -5rem;
            opacity: 1;
            visibility: visible;
        }

        &:after {
            background-position: 0 100%;

            @include --lg { background-position: center center; }
        }
    }

    &.show-desc {
        @include --lg {
            &:after {
                background-image: linear-gradient(
                    358.01deg,
                    rgba($color: var(--bg-color-rgb), $alpha: 0.6) 0%,
                    rgba($color: var(--bg-color-rgb), $alpha: 0.6) 100%
                );
            }

            & .description {
                opacity: 1 !important;
                visibility: visible !important;
                transform: none;
            }
        }
    }

    @include --lg {
        & .case-slide__content {
            bottom: 50%;
            transform: translateY(50%);

            & .description {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }

            @include --lg {
                bottom: auto;
                transform: translateY(0);

                & .description {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        & .case-slide__button {
            margin-top: -5rem;
            opacity: 1;
            visibility: visible;
        }

        &:after {
            background-position: 0 100%;

            @include --lg { background-position: center center; }
        }
    }

    &__overlay {
        width: 100%;
        height: auto;
        max-width: 100%;

        & img {
            width: 100%;
            height: auto;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    &__content {
        position: absolute;
        left: 7.8rem;
        right: 7.8rem; // todo
        bottom: -10rem;
        z-index: 1;
        transition: 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);

        & .description {
            height: 12.5rem;
            margin-top: 4rem;
            padding-left: 3rem;
            transition: 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
            transform: translateY(20rem);
            opacity: 0;
            visibility: hidden;

            @include --lg {
                margin-top: 1rem;
                padding-left: 2rem;
            }

            @include --md {
                margin-top: 4rem;
                padding-left: 3rem;
            }

            @include --xs {

                & > p { font-size: 1.1rem; }
            }
        }

        @include --xxl {
            left: 4rem;
        }

        @include --lg {
            bottom: auto;
            left: 4rem;
            top: 3rem;

            & .description {
                opacity: 0;
                visibility: hidden;
                transform: none;
            }
        }

        @include --sm {
            left: 2rem;
            right: 2rem;

            & .description {
                margin-top: 1rem;
                padding-left: 2rem;
            }
        }

        @include --xs {
            & .description { padding-left: 1rem; }
        }
    }

    &__subtitle {
        position: relative;
        font-family: $proxima;
        font-weight: bold;
        font-size: 1.6rem;
        color: var(--alter-white);
        letter-spacing: em(3/16);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-top: 1em;
    }

    &__button {
        position: absolute;
        z-index: 1;
        right: 7.8rem;
        top: 50%;
        margin-top: -7.2rem;
        opacity: 0;
        visibility: hidden;
        transition: 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);

        & .button {
            @include --xl {
                font-size: 1.4rem;
            }

            @include --sm {
                border: none;
                padding: 0;
                color: var(--orange);
                font-weight: 700;
                letter-spacing: em(3/16);
                position: relative;

                &:after {
                    content: "\E803";
                    font-family: "Fontello";
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 0.7em;
                    margin-left: 0.7rem;
                }

                &:hover {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        @include --xxl {
            right: 4rem;
        }

        @include --lg {
            right: auto;
            left: 4rem;
            top: auto;
            bottom: 2rem;
            opacity: 1;
            visibility: visible;
        }

        @include --sm {
            left: 2rem;
        }
    }
}

.techno {
    background-color: var(--white);
    padding-top: 0 !important;

    @include --xs {
        padding-top: 5rem !important;
    }

    &__wrap {
        height: 26.2rem;

        @include --lg {
            height: 18.7rem;
        }
    }

    & .title {
        margin-bottom: 9.5rem;

        @include --xxl {
            margin-bottom: 6rem;
        }

        @include --sm {
            margin-bottom: 4rem;
        }
    }

    &__line {
        margin-bottom: 7.7rem;
        overflow: hidden;

        & * {
            overflow: hidden;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include --lg {
            margin-bottom: 5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__item {
        margin: 0 5rem;
        font-size: 3rem;
        line-height: em(37/30);
        letter-spacing: em(4/30);
        font-weight: 900;
        text-transform: uppercase;
        color: var(--alter-light);
        transition: 0.8s;

        &:hover {
            color: var(--gray);
        }

        @include --lg {
            font-size: 2.4rem;
            margin: 0 4rem;
        }

        @include --xs {
            margin: 0 3.5rem;
        }
    }
}

.contacts {
    &__wrap {
        padding-top: 9.5rem;
        display: flex;

        @include --xxl {
            padding-top: 6rem;
        }

        @include --sm {
            padding-top: 4rem;
            flex-direction: column;
        }
    }

    &__form {
        width: 62%;

        @include --sm {
            width: 100%;
        }
    }

    &__meta {
        width: calc(100% - 62%);
        display: flex;

        @include --sm {
            width: 100%;
            margin-top: 5rem;
        }
    }
}

.meta {
    margin: auto;

    @include --md {
        padding-left: 3rem;
    }

    @include --sm {
        padding-left: 0;
        margin: 0;
    }

    &__item {
        margin-bottom: 7rem;
        display: flex;

        &:last-child {
            margin-bottom: 0;
        }

        @include --md {
            margin-bottom: 5rem;
            display: flex;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__link {
        font-family: $helvetica;
        font-weight: normal;
        font-size: 1.7rem;
        line-height: em(20/17);
        color: var(--white);
        transition: 0.3s;
        display: flex;
        align-items: center;

        & span {
            display: inline-block;
        }

        &:hover {
            color: var(--orange);
        }

        @include --lg {
            font-size: 1.6rem;
        }
    }

    &__icon {
        color: var(--orange);
        font-size: 3.2rem;
        margin-right: 2.4rem;

        @include --lg {
            font-size: 2.6rem;
            margin-right: 2rem;
        }

        @include --md {
            margin-right: 1.5rem;
        }
    }
}
