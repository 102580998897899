@font-face {
    font-family: "Proxima Nova";
    src: url("../../Fonts/ProximaNova-Bold.woff2") format("woff2"),
        url("../../Fonts/ProximaNova-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../../Fonts/ProximaNova-Black.woff2") format("woff2"),
        url("../../Fonts/ProximaNova-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../../Fonts/HelveticaNeueCyr-Roman.woff2") format("woff2"),
        url("../../Fonts/HelveticaNeueCyr-Roman.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "HelveticaNeueCyr";
    src: url("../../Fonts/HelveticaNeueCyr-Bold.woff2") format("woff2"),
        url("../../Fonts/HelveticaNeueCyr-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
