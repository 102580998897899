.form {
    background-color: var(--deep);
    padding: 5rem;
    box-shadow: 0rem 0.8rem 3rem rgba($color: var(--black-rgb), $alpha: 0.6);

    @include --xxl {
        padding: 4rem;
    }

    @include --md {
        padding: 3rem;
    }

    @include --xs {
        padding: 3rem 2rem;
    }

    &__row {
        display: flex;
        align-items: center;
        margin-bottom: 5rem;

        &:last-child {
            margin-bottom: 0;
        }

        @include --xxl {
            margin-bottom: 3.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @include --xs {
            flex-direction: column;
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__block {
        display: block;
        width: 100%;
        margin-right: 6rem;

        &:last-child {
            margin-right: 0;
        }

        @include --xxl {
            margin-right: 3.5rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include --md {
            margin-right: 3rem;

            &:last-child {
                margin-right: 0;
            }
        }

        @include --xs {
            margin-right: 0;
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__input-wrap {
        position: relative;

        &.is-focus {
            & .form__label {
                top: -0.5rem;
                font-size: 1rem;
            }
        }
    }

    &__label {
        position: absolute;
        font-family: $helvetica;
        font-size: 1.6rem;
        line-height: normal;
        font-weight: normal;
        color: var(--alter-light);
        top: 1.4rem;
        left: 1.5rem;
        transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
    }

    &__input {
        display: block;
        width: 100%;
        background-color: rgba($color: var(--white-rgb), $alpha: 0.03);
        font-size: 1.7rem;
        line-height: normal;
        padding: 1.2rem 1.5rem;
        font-family: $helvetica;
        font-weight: bold;
        border: none;
        color: var(--white);
        border-bottom: 0.1rem solid rgba($color: var(--white-rgb), $alpha: 0.1);
        border-radius: 0;
        transition: 0.2s;

        &_textarea {
            resize: none;
        }

        &:focus {
            border-color: var(--orange);
        }
    }
}

.checkbox {
    font-family: $helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: em(18/16);
    color: var(--alter-light);
    margin: -1rem 0;

    & input:checked ~ .checkbox__content:before {
        color: var(--orange);
    }

    &__content {
        position: relative;
        padding-left: 4rem;

        &:before {
            content: "\e80b";
            font-family: "Fontello";
            color: transparent;
            width: 2.5rem;
            height: 2.5rem;
            line-height: 2.5rem;
            background-color: rgba($color: var(--white-rgb), $alpha: 0.03);
            position: absolute;
            left: 0;
            top: calc((2.5rem - 1.7rem) / -2);
            text-align: center;
            font-size: 1.4rem;
        }

        @include --xs {
            padding-left: 3.5rem;

            &:before {
                width: 2.2rem;
                height: 2.2rem;
                line-height: 2.2rem;
            }
        }
    }

    &__text {
        & a {
            color: inherit;
            text-decoration: underline;
            transition: 0.2s;

            &:hover {
                color: var(--orange);
            }
        }
    }
}

.message {
    background-color: var(--deep);
    font-family: $helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: em(18/16);
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
    border: 0.1rem solid rgba($color: var(--white-rgb), $alpha: 0.7);
    box-shadow: 0 0 1.5rem rgba($color: var(--black-rgb), $alpha: 0.7);
    position: fixed;
    top: 15rem;
    right: 6rem;
    z-index: 100;

    @include --lg {
        right: 3rem;
    }

    @include --md {
        padding: 1rem 2rem;
    }

    @include --sm {
        right: 50%;
        transform: translateX(50%);
        top: 12rem;
    }

    @include --xs {
        font-size: 1.4rem;
    }

    &__icon {
        font-size: 3.4rem;
        margin-right: 1.5rem;

        @include --xs {
            font-size: 3rem;
        }
    }

    &__text {
        white-space: nowrap;
    }
}
