.embla-slider {
    position: relative;
    overflow: hidden;
    padding: 0 3rem;
    margin: 0 -3rem;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 3rem;
        background-color: var(--white);
        top: 0;
        bottom: 0;
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 3rem;
        background-color: var(--white);
        top: 0;
        bottom: 0;
    }

    @include --md {
        &:before,
        &:after {
            display: none;
        }
    }

    &:hover {
        & .embla-slider__btn {
            opacity: 1;
            visibility: visible;
        }
    }

    &__item {
        min-width: 100%;
    }

    &__buttons-container {
        @include --md {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 3.5rem;
            max-width: 32.6rem;
            margin: 0 auto;
        }

        @include --xs {
            max-width: 30rem;
        }
    }

    &__btn {
        color: var(--white);
        background-color: var(--orange);
        width: 6rem;
        height: 6rem;
        line-height: 6rem;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: calc(-6rem / 2);
        display: block;
        outline: none;
        transition: 0.3s;
        z-index: 100;
        font-size: 2.3rem;
        opacity: 0;
        visibility: hidden;

        &_prev {
            left: 0;

            &:before {
                content: "\e802";
                font-family: "fontello";
            }

            @include --xxl {
                left: 0.5rem;
            }

            @include --md {
                &:before {
                    content: "\E807";
                }
            }
        }
        &_next {
            left: calc(100% - 6rem);

            &:before {
                content: "\e803";
                font-family: "fontello";
            }

            @include --xxl {
                left: calc(100% - 5.5rem);
            }

            @include --md {
                &:before {
                    content: "\E80c";
                }
            }
        }

        @include --xxl {
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            font-size: 1.6rem;
            opacity: 1;
            visibility: visible;
        }

        @include --md {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            margin-top: 0;
            height: auto;
            width: auto;
            line-height: normal;
            border-radius: 0;
            font-size: 2.2rem;
            background-color: transparent;
        }
    }

    &__dots {
        font-family: $proxima;
        font-size: 1.6rem;
        line-height: normal;
        letter-spacing: em(3/16);
        font-weight: normal;
        width: 8rem;
        text-align: center;
        color: var(--alter-light);
    }

    &__selected-dot {
        font-weight: 800;
    }
}

.cases {
    .embla-slider__btn {
        @include --md {
            color: var(--deep);
        }
    }

    .embla-slider__dots {
        display: none;
        color: var(--deep);

        @include --md {
            display: block;
        }
    }
}

.plan {
    .embla-slider__buttons-container {
        @include --xs {
            padding-right: 3rem;
        }
    }
}
