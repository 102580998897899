@keyframes scroll {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    20% { opacity: 1; }

    100% {
        opacity: 0;
        transform: translateY(1.2rem);
    }
}

@keyframes slideInDown {
    0% { transform: translateY(125%); }

    100% { transform: none; }
}

@keyframes fadeInDown {
    to {
        transform: translateY(0) translateZ(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOutUp {
    0% {
        opacity: 1;
        visibility: visible;
    }

    99% {
        transform: translateY(-2em) translateZ(0);
        opacity: 0;
        visibility: visible;
    }

    100% { visibility: hidden; }
}

.animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.animate__animated.animate__delay-1s { animation-delay: 1s; }
.animate__animated.animate__delay-1_5s { animation-delay: 1.5s; }
.animate__animated.animate__delay-2s { animation-delay: calc(1s * 2); }

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInUp { animation-name: fadeInUp; }

@keyframes fadeInUpSlow {
    from {
        opacity: 0;
        transform: translate3d(0, 10rem, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInUpSlow { animation-name: fadeInUpSlow; }

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-10rem, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInLeft { animation-name: fadeInLeft; }

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(10rem, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInRight { animation-name: fadeInRight; }

@keyframes backInRight {
    0% {
        transform: translateX(50rem) scale(0.7);
        opacity: 0;
    }

    80% {
        transform: translateX(0) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.animate__backInRight { animation-name: backInRight; }

@keyframes backInUp {
    0% {
        transform: translateY(20rem) scale(0.7);
        opacity: 0;
    }

    80% {
        transform: translateY(0) scale(0.7);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.animate__backInUp { animation-name: backInUp; }